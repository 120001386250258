<script setup lang="ts">
const props = defineProps({
  track: {
    type: Object as () => ITrackEntity,
    required: true
  },
  size: {
    type: String,
    default: 'default',
    validator(value: string) {
      return ['compact', 'default', 'big'].includes(value)
    }
  }
})

const isSizeCompact = computed(() => props.size === 'compact')

const textStyles = computed(() => {
  if (isSizeCompact.value) {
    return ['text-size-xs']
  }

  return ['text-size-sm']
})
</script>

<template>
  <NuxtLink
    :to="`/tracks/${track.trackNumber}`"
    :title="`${track.artist} - ${track.track}`"
    class="p-2 b-rd-lg flex items-start other-track-item">
    <div :class="[isSizeCompact ? 'mr-2' : 'mr-3', `cover-size-${size}`]">
      <img
        :src="track.cover"
        alt="cover"
        class="w-full b-rd-md cover">
    </div>
    <div class="whitespace-nowrap select-none">
      <div :class="['overflow-hidden text-ellipsis font-700 track', textStyles]">{{ track.track }}</div>
      <div :class="['overflow-hidden text-ellipsis text-size-xs artist', textStyles]">{{ track.artist }}</div>      
      <div
        v-if="!isSizeCompact"
        class="flex items-center">
        <div :class="['font-400 text-size-2 line-height-normal textStyles']">
          {{ track.label }}
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<style scoped lang="scss">
.other-track {
  &-item {
    border: 1px solid var(--color-primary-light);
    background-color: var(--color-primary-medium);
    color: var(--color-secondary-light);
    
    .cover {
      border: 1px solid var(--color-primary-light);

      &-size {
        &-compact {
          width: 40px;
          height: 40px;
        }

        &-default {
          width: 60px;
          height: 60px;
        }

        &-big {
          width: 120px;
          height: 120px;
        }
      }
    }

    .track,
    .artist {
      max-width: 180px;
    }
  }
}
</style>